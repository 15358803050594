:root{
    --text-primary:#000;
    --bgPrimary:#fff;
   --bgSecondary: #e5dcdc;
   --pink: #1c1515;
   --light-blue: #e6f7ff;
   --border: #d9d9d9;
}
.Error
{
background-color: #0b598e !important;
justify-content:space-around;
}

.nav-container{
    /* position: fixed; */
    /* left: 0;
    margin: 10px;
    height: 100vh;
    margin: 0px; */
    /* background-color: rgb(225, 23, 23);   */
} 

.logo-container{
    /* background-color:rgb(2, 2,2);
    display: flex;
    padding: 20px;
    align-items: center;
    width: 100%; */
}
.logo{
    /* margin:0 0 0 15px;
    color:#bd0000 !important; */
}
.logo a{
   /* color: white; */
}
a.ant-typography, .ant-typography a{
    color: white !important;
}
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    /* background: #000 !important; */
}



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a:hover{
    color: #bd0000 !important;
}

.app{
    /* display: flex; */
    overflow: hidden; 
}

.navbar{
    /* flex: 0.2; */
    /* background-color: rgb(73, 10, 10); */
    /* background-color:antiquewhite; */
   /* // border-radius: 30px; */
}
/*
.main{
    flex: 0.8;
    width: 100%;
    border-radius: 30px; 
}
*/
.routes{
    padding: 20px;
    /* background-color: antiquewhite; */
}

.ant-card-bordered {
    border-radius: 25px;
}
.heading{
    text-align: center;
}
.menu-control-container{
    display: none !important;
    position: absolute !important;
    right: 10px !important;
    top: 25px !important;
    font-size: 1.2rem !important;
    background-color: var(--bgSecondary) !important;
    border: none !important;
}

.loader{
    height: 81vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:1300px){
    .main{
        /* margin-left: 50px; */
    }
}

@media screen and (max-width:1170px){
    .main{
        /* margin-left: 50px; */
    }
}

@media screen and (max-width:1000px){
    .main{
        /* margin-left: 100px; */
    }
}

@media screen and (max-width:800px){
    .app{
        flex-direction: column;
        overflow: hidden;
    }

    .navbar{
        flex: 1;
    }

    .main{
        /* flex: 1;
        margin-top: 90px;
        margin-left: 0px;
        margin-right: 10px; */
    }

    .nav-container{
        height: 8vh;
        position:fixed;
        width: 100%;
        z-index: 100;
        background-color: var(--bgSecondary);
    }
  
    .menu-control-container{
        display: block !important;
    }

    .ant-menu{
        position: absolute;
        right: 0px;
    }
    .home-title{
        /* font-size: 1.4rem !important; */
    }
    .show-more
    {
        /* font-size: 1.3rem !important; */
        color: blue!important;
        /* background-color: #06357a; */
    }
}

.coin-detail-container{
    margin: 30px;
}

.coin-heading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid var(--border);
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 10px;
}

.coin-heading-container .coin-name{
    font-weight: 900;
    color: var(--pink);
}

.coin-heading-container p{
    font-size: 1rem;
    opacity: 0.9;
    margin-bottom: 20px;
}

.stats-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.stats-container h2{
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 20px;
    color: var(--pink);
}

.coin-details-heading{
    font-weight: 700 !important;
    margin-top: 20px !important;
    color: var(--pink) !important;
}

.coin-stats{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--border);
    font-size: 1rem;
    opacity: 0.9;
    padding: 20px;

}

.coin-stats-name{
    display: flex;
    gap: 10px;
    font-size: 1rem;
}

.stats{
    font-weight: 800;
}

.coin-value-statistics-heading p{
    font-size: 1rem;
    opacity: 0.9;
}

.coin-desc-link{
    display: flex;
    gap: 40px;
    margin-top: 40px;
    padding-top: 20px;
}

.coin-desc-link h2{
    font-weight: 700;
    color: var(--pink);
}

.coin-desc-link p{
    font-size: 1rem;
    opacity: 0.9;
}

.coin-desc-link a{
    color: var(--pink);
}

.coin-desc-link h3{
    font-weight: 700;
}

.coin-desc{
    flex: 0.5;
}

.coin-links{
  padding: 0px 20px;
    flex: 0.5;
}

.coin-link{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border);
    padding: 20px;
}

.link-name{
    text-transform: capitalize;
    font-size: 1rem;
}

.coin-link a{
    color: var(--pink);
    font-weight: 700;
    font-size: 1rem;
}

.coin-link:hover, .coin-stats:hover{
    background-color: var(--bgSecondary);
}
a.ant-typography, .ant-typography a{
    color: rgb(75, 98, 144) !important;
}
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background: #000 !important;
}
.ant-card-bordered {
    border-radius: 25px !important;
}
.heading{
    text-align: center;
}

/* @media screen and (max-width:1000px){
    .stats-container{
        flex-direction: column;
    }
    .coin-desc-link{
        flex-direction: column;
    }
    .stats-container h2{
        margin-top: 0px;
    }
}

@media screen and (max-width: 500px){
    .coin-links{
        padding: 0px;
    }
    .coin-detail-container{
        margin: 0;
    }
}
@media screen and (max-width: 500px){
    .heading{
        margin-top: 20px;
    }
} */

.select-news{
    width: 180px;
}
.news-card{
    min-height: 300px !important;
}
.news-image-container{
    display: flex !important;
    justify-content: space-between !important;
}
.news-title{
width: 70%;
}

.news-image-container .img{
    width:100px;
    height:100px;
}
.news-card p{
    color: black;
    margin: 10px 0px !important;
}
.provider-container{
    display: flex;
    justify-content: space-between;
}
.provider-name{
    margin-left: 10px;
}

.chart-header{
    display: flex;
    justify-content: space-between;
    gap: 50px;
    color: #0071bd;
}
.chart-title{
    color: #080e12 !important;
}
.price-container{
    display: flex !important;
    gap: 20px !important;
    align-items: center !important;
    flex-wrap: wrap !important;
}
.price-change{
    font-weight: 900 !important;
}
.current-price{
    margin-top: 0px !important;
    font-weight: 900 !important;
}
.home-heading-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}
.ant-typography .ant-typography  a{
    color:rgb(34, 22, 208)!important;
}
.exchange-image{
    margin: 0px 10px !important;
}
.search-crypto{
    margin: 20px auto 30px auto;
    width: 250px;
}
.crypto-card-container{
    min-height: 65vh !important;
}
.crypto-card{
    min-width: 250px;
    
}
.crypto-card .crypto-image{
    width:35px;
}
.select-timeperiod{
    width: 200px !important;
    margin-top: 20px !important;
}

.footer {
    background-color:rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: center;
}

@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
}
.row
{
text-align: center;
}
html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

/* 
 
/* navbar style start  */
.main-nav {
  width: 100%;
  height: 10rem;
  display: grid;
  grid-template-columns: 10rem 1fr 1fr;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.logo {
  display: grid;
  grid-column: 2/3;
  justify-content: start;
  align-items: center;
}
.logo img
{
    height: 50px;
    padding: 8px
}
.menu-link {
  grid-column: 3/5;
}

.menu-link ul {
  height: 10rem;
  display: flex;
  justify-content:space-evenly;
  align-items:center;
}
.logo h2 {
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#eee, rgb(69, 57, 248));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo h2 span {
  font-size: 3.5rem;
}
.menu-link ul li {
  font-size: 1.8rem;
  /* padding: 10px; */
}

.menu-link ul li a {
  text-transform: capitalize;
  color: rgba(0, 0, 3, 0.8);
  transition: 0.5s;
}
.menu-link ul li:hover > a {
  transform-origin: left;
  color: rgba(0, 0, 3, 1);
  text-decoration: underline;
  transition: 0.5s;
}
/* .social-media ul li {
  font-size: 1.8rem;
}

.hamburger-menu {
  display: none;
}

.facebook {
  color: #3b5998;
}

.instagram {
  color: #c32aa3;
}

.youtube {
  color: #ff0000;
}

/* hero section   */

.hero-section {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* 
.hero-section p {
  font-size: 3rem;
  text-transform: capitalize;
} */

/* .hero-section h1 {
  font-size: 5rem;
  text-transform: uppercase;
  text-align: center;
}  */

/* responsive css style  */
@media (max-width: 1080px) {
  .main-nav {
    height: 8rem;
    grid-template-columns: 2rem 3fr 3fr 1fr 2rem;
  }

  .logo,
  .menu-link ul,
  .social-media ul {
    height: 8rem;
  }
}

/* responsive css style  */
@media (max-width: 998px) {
  .main-nav {
    height: 7rem;
    grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
  }

  .menu-link {
    display: none;
  }

  .logo,
  .social-media ul {
    height: 7rem;
  }

  .mobile-menu-link {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
  }

  .mobile-menu-link {
    background-color: white;
    height: 20rem;
    display: grid;
    grid-column: 2/5;
    align-items: center;
    padding-left: 3rem;
    transition: all 2s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .mobile-menu-link ul {
    height: 20rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: start;
  }

  .mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }

  .social-media {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
  }

  .social-media-desktop {
    height: 0;
    display: none;
  }

  .social-media {
    height: 7rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 798px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
  }

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 520px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
  }

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .logo h2 {
    font-size: 2rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

 .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }

  /* hero section  */

  .hero-section h1 {
    font-size: 3.8rem;
  }
}
